import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../scss/generationChoosePage.css';

const GenerationChoosePage = ({ authData }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const handleInstaStory = () => {
        navigate(`/insta-story/${id}`);
    };

    const handleInstaPost = () => {
        navigate(`/insta-post/${id}`);
    };

    const handleTikTok= () => {
        navigate(`/tik-tok/${id}`);
    };

    return (
        <section className="generation-choose-page">
            <h3>Wybierz co chcesz wygenerować</h3>
            <p>Post ID: {id}</p>
            <div className="button-container">
                <button onClick={handleInstaStory}>Insta Story</button>
                <button onClick={handleTikTok}>Tik Tok</button>
                <button onClick={handleInstaPost}>Insta Post</button>
            </div>
        </section>
    );
};

export default GenerationChoosePage;
