import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from './Loader';
import '../scss/regeneratedDetails.css';
import {toast} from "react-toastify";

const RegeneratedDetails = ({ authData }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const token = authData.token;
    const [data, setData] = useState(null);
    const [title, setTitle] = useState('');
    const [summary, setSummary] = useState('');
    const [regenTitle, setRegenTitle] = useState('');
    const [regenSummary, setRegenSummary] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!token) {
            return;
        }

        fetch(`https://admin-service.verita.news/regeneration/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return;
                }
                if (!response.ok) {
                    throw new Error('Błąd sieci');
                }
                return response.json();
            })
            .then(data => {
                setData(data);
                setTitle(data.post.title);
                setSummary(data.post.summary);
                setRegenTitle(data.regen.title);
                setRegenSummary(data.regen.summary);
            })
            .catch(error => console.error('Błąd podczas pobierania danych:', error));
    }, [token, id]);

    const handleDelete = () => {
        setIsLoading(true);
        const fetchPromise = fetch(`https://admin-service.verita.news/regeneration/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return Promise.reject();
                }
                if (!response.ok) {
                    return Promise.reject();
                }
            })
            .finally(() => setIsLoading(false));

        toast.promise(
            fetchPromise,
            {
                success: { render: 'Regeneracja została usunięta', className: 'toast-delete', icon: false },
                pending: { render: 'Poczekaj chwilkę...', className: 'toast-pending', icon: true },
                error: { render: 'Coś poszło nie tak', className: 'toast-error' },
            }
        )
            .then(() => navigate('/'))
            .catch(() => {});
    };

    const handleUpdate = () => {
        setIsLoading(true);
        const pub = {
            title: title,
            summary: summary,
        };

        const fetchPromise = fetch(`https://admin-service.verita.news/regeneration/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(pub),
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return Promise.reject();
                }
                if (!response.ok) {
                    return Promise.reject();
                }
            })
            .finally(() => setIsLoading(false));

        toast.promise(
            fetchPromise,
            {
                success: { render: 'Zaktualizowano wygenerowany post', className: 'toast-regenerate', icon: false },
                pending: { render: 'Poczekaj chwilkę...', className: 'toast-pending', icon: true },
                error: { render: 'Coś poszło nie tak', className: 'toast-error' },
            }
        )
            .then(() => navigate('/'))
            .catch(() => {});
    };

    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userType');
        window.location.reload();
    };

    if (!data) {
        return (
            <div className="loader-wrapper">
                <Loader />
            </div>
        );
    }

    return (
        <div className="regenerated-details-container">
            <div className="form-element">
                <label>Stary Tytuł</label>
                <input
                    type="text"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    disabled={isLoading}
                />
            </div>
            <div className="form-element">
                <label>Stare Streszczenie</label>
                <textarea
                    value={summary}
                    onChange={e => setSummary(e.target.value)}
                    disabled={isLoading}
                />
            </div>
            <div className="form-element">
                <label>Nowy Tytuł (wygenerowany)</label>
                <input
                    type="text"
                    value={regenTitle}
                    readOnly
                    className="regenerated-input"
                />
            </div>
            <div className="form-element">
                <label>Nowe Streszczenie (wygenerowane)</label>
                <textarea
                    value={regenSummary}
                    readOnly
                    className="regenerated-textarea"
                />
            </div>
            <div className="button-container">
                <button className="delete-button" onClick={handleDelete} disabled={isLoading}>Usuń</button>
                <button className="update-button" onClick={handleUpdate} disabled={isLoading}>Aktualizuj</button>
            </div>
        </div>
    );
};

export default RegeneratedDetails;
