import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import {ReactComponent as TrashIcon} from "../img/icons/trashIcon.svg";
import {toast} from "react-toastify";
import '../scss/customToast.css';

const RegeneratedDashboard = ({authData}) => {

    const [data, setData] = useState(null);
    const token = authData.token;
    const navigate = useNavigate();
    const regeneradedPublications = data ? data.regenerated : [];

    const handleRegeneratedPublicationClick = (id) => {
        navigate(`/regenerated/${id}`);
    }

    useEffect(() => {
        if (!token) {
            return;
        }

        fetch('https://admin-service.verita.news/', {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return;
                }
                if (!response.ok) {
                    toast('Coś poszło nie tak', {
                        className: 'toast-error',
                        icon: false,
                    });
                }
                return response.json();
            })
            .then(data => setData(data))
            .catch(error => console.error('Błąd podczas pobierania danych:', error));
    }, [token]);
    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('userType');
        window.location.reload();
    };


    // const handleRegenerationDelete = (id) => {
    //     fetch(`https://admin-service.verita.news/regeneration/${id}`, {
    //         method: 'DELETE',
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //         },
    //     })
    //         .then(response => {
    //             if (response.status === 403) {
    //                 handleLogout();
    //                 return;
    //             }
    //             if (!response.ok) {
    //                 toast('Coś poszło nie tak', {
    //                     className: 'toast-error',
    //                     icon: false,
    //                 });
    //             } else {
    //                 toast('Regeneracja została usunięta', {
    //                     className: 'toast-delete',
    //                     icon: false,
    //                 });
    //             }
    //         })
    //         .then(() => {
    //             window.location.reload();
    //             toast('Regeneracja została usunięta', {
    //                 className: 'toast-delete',
    //                 icon: false,
    //             });
    //         })
    //         .catch(error => console.error('Błąd podczas usuwania:', error));
    // };

    const handleRegenerationDelete = (id) => {
        fetch(`https://admin-service.verita.news/regeneration/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.status === 403) {
                    handleLogout();
                    return;
                }
                if (!response.ok) {
                    toast('Coś poszło nie tak', {
                        className: 'toast-error',
                        icon: false,
                    });
                } else {
                    setData(prevData => {
                        const updatedRegenerated = prevData.regenerated.filter(pub => pub.regenerationId !== id);
                        return {
                            ...prevData,
                            regenerated: updatedRegenerated,
                        };
                    });
                    toast('Regeneracja została usunięta', {
                        className: 'toast-delete',
                        icon: false,
                    });
                }
            })
            .catch(error => console.error('Błąd podczas usuwania:', error));
    };


    if (!data) {
        return (
            <div className="dashboard-loader-wrapper">
                <Loader/>
            </div>
        );
    }

    return (
        <section className="dashboard-container">
            <div className="dashboard-container-unpublished">
                <h3>Wygenerowane ponownie</h3>
                {regeneradedPublications.length === 0 ? (
                    <ul>
                        <li className="li-brak">
                            <p className="regenerated-brak">
                                Aktualnie nie ma nowych wygenerowanych ponownie postów
                            </p>
                        </li>
                    </ul>
                ) : (
                    <ul className="ul-regeneration">
                        {regeneradedPublications.map((pub, index) => {
                            const isFirstElement = index === 0;
                            return (
                                <React.Fragment key={pub.regenerationId}>
                                    {isFirstElement && <div className="extra-separator"></div>}
                                    <li className="li-regeneration" key={pub.regenerationId}
                                        onClick={() => handleRegeneratedPublicationClick(pub.regenerationId)}>
                                        <div className="text-flags-wrapper">
                                            <h4>{pub.title}</h4>
                                        </div>
                                        <div className="dashboard-icons-wrapper">
                                            <div className="separator-small"></div>
                                            <TrashIcon className="dashboard-icon trashIcon" onClick={(event) => {
                                                event.stopPropagation();
                                                handleRegenerationDelete(pub.regenerationId);
                                            }}/>
                                        </div>
                                    </li>
                                    <div className="separator"></div>
                                </React.Fragment>
                            );
                        })}
                    </ul>
                )}
            </div>
        </section>
    );

};

export default RegeneratedDashboard;