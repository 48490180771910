import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/login.css';
import logo from '../img/veritaLogo-Full-Black.svg';

const Login = ({ setAuthData }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch('https://admin-service.verita.news/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd podczas logowania');
            }

            const data = await response.json();
            setAuthData({
                token: data.token,
                userType: data.userType,
            });
            setError(null);
            navigate('/');
        } catch (error) {
            setError(error.message);
            console.error('Błąd podczas logowania:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <main>
            <div className="login-container">
                <img src={logo} alt="verita news logo"/>
                <form onSubmit={handleLogin}>
                    <div className="login-container-inputs">
                        <div>
                            <label>
                                Nazwa użytkownika:
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                    disabled={isLoading}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Hasło:
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    disabled={isLoading}
                                />
                            </label>
                        </div>
                        {error && <p style={{ color: 'red' }}>Błędne dane logowania frajerze</p>}
                    </div>
                    <div className="login-container-buttonWrapper">
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? 'Logowanie...' : 'Zaloguj się'}
                        </button>
                    </div>
                </form>
            </div>
            <div className="login-note">
                <h4>Masz jakiś problem?</h4>
                <p>Skontaktuj się z głównym administratorem</p>
            </div>
        </main>
    );
};

export default Login;
