import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/generationEntryPage.css';

const GenerationEntryPage = ({ authData }) => {
    const [postId, setPostId] = useState('');
    const navigate = useNavigate();

    const handleSearch = () => {
        if (postId.trim()) {
            navigate(`/generation/${postId}`);
        } else {
            alert('Proszę wprowadzić ID postu.');
        }
    };

    return (
        <section className="generation-entry-page">
            <h3>Wyszukaj post</h3>
            <div className="generation-form">
                <input
                    type="text"
                    placeholder="Wprowadź ID postu"
                    value={postId}
                    onChange={(e) => setPostId(e.target.value)}
                />
                <button onClick={handleSearch}>Wyszukaj</button>
            </div>
        </section>
    );
};

export default GenerationEntryPage;
